<template>
    <div>
        <div class="op-balb">
            <img src="/img/Untitled-1-07.png" alt="">
        </div>
        <div class="row slider align-items-center">
            <div class="col-7">
                <div class="balb"><img src="/img/Untitled-1-05.png" alt="orange-balb"></div>
                <img :src="'/img/'+category.logo" :ref="'mainlogo'+category.id" :id="'mainlogo'+category.id" class="main-logo" :alt="category.name">
                <div class="lights animate-zoom">
                    <img src="/img/upLight.png" class="up-light" :class="{'up-animation' : upAnimation}" v-on:animationend="removeClass()" alt="lights">
                    <img src="/img/downLight.png" class="down-light" :class="{'down-animation' : downAnimation}" v-on:animationend="removeClass()" alt="lights">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'MainSlide',
        data :function(){
            return {
                category:{
                    id:1,
                    name:'آذرپژوهش پلاست',
                    logo:'Untitled-1-02.png',
                },
                logos:[
                            {
                                id:1,
                                name:'Untitled-1-01.png',
                                hidden : false,
                            },
                            {
                                id:2,
                                name:'Untitled-1-02.png',
                                hidden : true,
                            },
                            {
                                id:3,
                                name:'Untitled-1-03.png',
                                hidden:true,
                            }
                        ],
                    upAnimation:false,
                    downAnimation:false,
                    activeLogo:1,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>